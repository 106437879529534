import React, { useEffect, useCallback, useRef } from "react";
import './NightBanner.css';

const starCount = 45; // Number of stars to create
const shootingStarInterval = 3000; // Interval between shooting stars in milliseconds

export function NightBanner({ heading, subtitle, button }) {
    const starsRef = useRef(null);

    const createStar = useCallback(() => {
        const star = document.createElement("div");
        star.className = "star";
        star.style.top = `${Math.random() * 100}%`;
        star.style.left = `${Math.random() * 100}%`;
        starsRef.current.appendChild(star);
    }, []);

    const createShootingStar = useCallback(() => {
        const shootingStar = document.createElement("div");
        shootingStar.className = "shooting-star";
        shootingStar.style.top = `${Math.random() * 100}%`;
        shootingStar.style.left = `${Math.random() * 100}%`;
        starsRef.current.appendChild(shootingStar);
        setTimeout(() => {
            if (starsRef.current.contains(shootingStar)) {
                starsRef.current.removeChild(shootingStar);
            }
        }, 3000);
    }, []);

    const generateStars = useCallback(() => {
        for (let i = 0; i < starCount; i++) {
            createStar();
        }
    }, [createStar]);

    const randomizeShootingStarInterval = useCallback(() => {
        const interval = Math.random() * shootingStarInterval;
        setTimeout(() => {
            createShootingStar();
            randomizeShootingStarInterval();
        }, interval);
    }, [createShootingStar]);

    useEffect(() => {
        generateStars();
        setTimeout(createShootingStar, Math.random() * shootingStarInterval); // Initial shooting star
        randomizeShootingStarInterval();
    }, [generateStars, createShootingStar, randomizeShootingStarInterval]);

    return (
        <div id="stars" ref={starsRef} className="night-banner">
            {heading && <h1>{heading}</h1>}
            {subtitle && <h2>{subtitle}</h2>}
            {button && <div className="night-banner-button-wrapper">{button}</div>}
        </div>
    );
}
