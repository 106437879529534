import React from "react";

import {
  collectionOptionBasicEn,
  collectionOptionBasicNo,
} from "../../Components/ShopifySetup/ShopifyCollection";
import { activeLanguage } from "../../locale/locale";
import { t } from "../../locale/locale";
import Collection from "../../Components/Collection";
import { useScrollToCollection } from "./useScrollToCollection";
import ColorsBgVideo from '../../video/eshop-video-bg-solids.mp4'

const collections = [
  { id: 576665813265, title: t("collection-colours-id-1-title") },
  { id: 576673808657, title: t("collection-colours-id-2-title") },
  { id: 565100478737, title: t("collection-colours-id-3-title") },
  { id: 576776110353, title: t("collection-colours-id-4-title") },
];

export default function CollectionPatterns() {
  useScrollToCollection(collections);

  function returnCorrectButton() {
    if (activeLanguage === "en") {
      return collectionOptionBasicEn;
    } else if (activeLanguage === "no") {
      return collectionOptionBasicNo;
    } else {
      throw new Error("Invalid active language");
    }
  }
  return (
    <div>
      <Collection
        videoBackground={ColorsBgVideo}
        // header={t("collection-header")}
        name={t("collection-name-colours-1")}
        subtitle={t("collection-subtitle-colours-1")}
        collections={collections}
        options={returnCorrectButton()}
        className="eshop-collection"
        instagramIds={[
          "DASSPV9v5-j", 
          "DASf-ItMkxE", 
          "DASm2VisHQv", 
          "DAS0leTv02Y", 
          "DATW7oSByp5", 
          "DATdzhgIzGj", 
          "DATkqjlsPaV", 
          "DAXb0sspma5", 
          "DAXpkYtMfWm", 
          "DAXwbQAt9bp", 
          "DAX-LC4p6qe", 
          "DAYghU0NaK-", 
          "DAYnYx4svlN", 
          "DAYuQZKKAV_"
        ]}
      />
    </div>
  );
}
