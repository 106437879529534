import React from "react";
import "./ContactPage.css";
import { t } from "../locale/locale";

export default function ContactPage() {
  function sendAnEmail() {
    window.location.href = "mailto:" + t("contact-page-email");
  }
  return (
    <section className="section-contact-us">
      <h1 className="h1-contact-page">{t("contact-page-heading")}</h1>
      <button onClick={sendAnEmail} className="contact-page-button">
        {t("contact-page-button-text")}
      </button>
      <h2>{t("contact-page-names")}</h2>
      <ul>
        <li>
          <a
            className="contact-page-email-link"
            href={"mailto:" + t("contact-page-email")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("contact-page-email")}
          </a>
        </li>
        <li>
          {" "}
          <a
            className="contact-page-web-link"
            href={"https://" + t("contact-page-website")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("contact-page-website")}
          </a>
        </li>
        <li></li>
      </ul>
    </section>
  );
}
