import no from "./no.json";
import en from "./en.json";

const defaultLanguage = "no";

// "no" or "en"
export const activeLanguage =
  new URLSearchParams(window.location.search).get("language") ||
  defaultLanguage;

export function setLanguage(language) {
  const url = new URL(window.location.href);
  url.searchParams.delete("language");
  url.searchParams.append("language", language);
  window.location.href = url.href;
}

export function t(localeKey) {
  if (activeLanguage === "en") {
    return en[localeKey];
  } else if (activeLanguage === "no") {
    return no[localeKey];
  } else {
    throw new Error("Invalid active language");
  }
}
