import React from "react";
import "./OrderProcess.css";
import { t } from "../locale/locale";

import OrderProcessPic1 from "../img/Illustrations/IMG_4754-2.jpg";
import OrderProcessPic2 from "../img/product-pictures/IMG_4729.png";

export default function OrderProcess() {
  return (
    <div className="section-wrapper">
      {/* *******FIRST SECTION ****** */}

      <section className="order-process-page section-order-process-1 grid grid-2-columns">
        <div className="order-process-part-1">
          {/* How does it work? */}
          <h3>{t("order-process-section-1-h3")}</h3>
          {/* We operate on a zero-waste principle. */}
          <h2>{t("order-process-section-1-h2")}</h2>
          {/* We don’t store items; instead, we order materials as needed to fulfill your orders. */}
          <p>{t("order-process-section-1-paragraph-0")}</p>
          {/* We periodically open our e-shop for orders, then close it to procure the materials. */}
          <p>{t("order-process-section-1-paragraph-1")}</p>
        </div>
        {/* Image 1 */}
        <img
          className="order-process-picture-1 order-process-pictures"
          src={OrderProcessPic1}
          alt={t("order-process-picture-alt-1")}
        />
        {/* Image 2 */}
        <img
          className="order-process-picture-2 order-process-pictures"
          src={OrderProcessPic2}
          alt={t("order-process-picture-alt-2")}
        />
        <div className="order-process-part-2">
          {/* Our capacities are limited, we are a tiny handmade business, so once we reach our limit in orders, we close the e-shop. */}
          <p>{t("order-process-section-2-paragraph-0")}</p>
          {/* Once the materials arrive, we start crafting your orders. This way, we only produce what we sell and there is no waste. */}
          <p>{t("order-process-section-2-paragraph-1")}</p>
          {/* As soon as your order is completed, we ship it out to you. This means you won't have to wait for all orders to be fulfilled. It could take as little as 7 days and it never exceeds more than 6 weeks. */}
          <p>{t("order-process-section-2-paragraph-2")}</p>
          <span className="follow-us-on-instagram">
            <a
              href="https://www.instagram.com/maama_handmade/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* Follow us on Instagram */}
              {t("order-process-section-2-paragraph-3a")}
            </a>
          </span>
          {/* or */}
          <span>{t("order-process-section-2-paragraph-3b")}</span>
          <span className="next-opening-link">
            <a
              href="https://maama.no/next-e-shop-opening"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* check our website */}
              {t("order-process-section-2-paragraph-3c")}
            </a>
          </span>
          {/* to stay updated on when our e-shop opens. */}
          <span>{t("order-process-section-2-paragraph-3d")}</span>
          {/* Thank you for supporting our sustainable approach! */}
          <span>{t("order-process-section-2-paragraph-4")}</span>
        </div>
      </section>
    </div>
  );
}
