import { useState } from "react";
import jsonp from "jsonp";
import { t } from "../locale/locale";
import "./MailchimpSignUpForm.css";

export default function MailchimpSignUpForm() {
  const [email, setEmail] = useState("");
  const [submission, setSubmission] = useState("ready");

  function onSubmit(e) {
    setSubmission("submitting");
    e.preventDefault();

    const url =
      "https://maama.us22.list-manage.com/subscribe/post-json?u=607143ad7ba9af433a73c53cb&amp;id=51f4b56305&amp;f_id=0042c3e1f0";
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {
      setSubmission(
        data.result === "success" ? "submittedSuccessfully" : "submissionFailed"
      );
    });
  }

  return (
    <div>
      {(submission === "ready" || submission === "submitting") && (
        <form className="signup-form" onSubmit={onSubmit}>
          <h2 className="signup-form-h2">{t("mailchimp-signupform-h2")}</h2>
          <label>{t("mailchimp-signupform-email")}</label>
          <input
            type="email"
            name="EMAIL"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <button type="submit" disabled={submission === "submitting"}>
            {submission === "submitting"
              ? t("mailchimp-signupform-button-submitting")
              : t("mailchimp-signupform-button")}
          </button>
        </form>
      )}
      {submission === "submittedSuccessfully" && (
        <h2 className="successfully-subscribed-h2">
          {t("successfully-subscribed-h2")}
        </h2>
      )}
      {submission === "submissionFailed" && (
        <h2 className="subscription-failed-h2">
          {t("mailchimp-subscription-failed-h2")}
        </h2>
      )}
    </div>
  );
}
