import React from "react";
import "./ImportantInformation.css";
import { t } from "../locale/locale";

export default function PrivacyPolicy() {
  return (
    <div className="section-important-information">
      <h1>{t("privacy-policy-h1")}</h1>
      <div className="paragraph-section-important-information">
        {/* Foreword */}
        <div className="paragraph-important-information">
          {t("privacy-policy-paragraph-foreword")}
        </div>

        {/* Cookies */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("privacy-policy-paragraph-1-title")}
          </div>
          {t("privacy-policy-paragraph-1")}
        </div>

        {/* Google Analytics */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("privacy-policy-paragraph-2-title")}
          </div>
          {t("privacy-policy-paragraph-2")}
        </div>

        {/* Shopify Integration */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("privacy-policy-paragraph-3-title")}
          </div>
          {t("privacy-policy-paragraph-3")}
        </div>

        {/* Your Consent */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("privacy-policy-paragraph-4-title")}
          </div>
          {t("privacy-policy-paragraph-4")}
        </div>

        {/* Updates to Privacy Policy */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("privacy-policy-paragraph-5-title")}
          </div>
          {t("privacy-policy-paragraph-5")}
        </div>

        {/* Data Subject Rights */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("privacy-policy-paragraph-7-title")}
          </div>
          {t("privacy-policy-paragraph-7")}
        </div>

        {/* Contact Us */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("privacy-policy-paragraph-6-title")}
          </div>
          {t("privacy-policy-paragraph-6")}
        </div>
      </div>
      <div class="cky-audit-table-element"></div>
    </div>
  );
}
