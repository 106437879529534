import React, { useState, useEffect } from "react";
import "./MainPage.css";

import MaamaLogo2 from "../img/logos/maama_logo_colours.png";
import ProductPic1 from "../img/product-pictures/IMG_7667.jpg";
import ProductPic2 from "../img/product-pictures/brown_pumpkin_square2.jpg";

// import { InstagramEmbed } from "react-social-media-embed";
import { t } from "../locale/locale";
import Button from "../Components/Button";
import {
  hasCollectionsLaunched,
  collectionsLaunchTime,
  // collectionsEndTime,
  hasNoNextCollectionsLaunchOrEnd,
  isNightshoppingTime,
  shouldHideCollectionsCountdonwn,
  maxOrdersForRunningCollections,
} from "../helpers/helpers";
import IntroductionVideo from "../Components/Videos/IntroductionVideo";
import MailchimpSignupForm from "../Components/MailchimpSignUpForm";
import Countdown from "../Components/Countdown";
import { ActiveCollectionsSection } from "./Collections/ActiveCollectionsSection";
import { NightBanner } from "../Components/NightBanner";
import { MaxOrdersAlert } from "../Components/MaxOrdersAlert";

export default function MainPage() {
  const [header, setHeader] = useState(t("home-header-heading"));

  useEffect(() => {
    const contentChanges = [
      {
        header: t("home-header-heading-2"),
      },
      {
        header: t("home-header-heading-3"),
      },
      {
        header: t("home-header-heading"),
      },
      // Add more content changes as needed
    ];

    let currentIndex = -1;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % contentChanges.length;
      setHeader(contentChanges[currentIndex].header);
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  function goToEshop() {
    window.location.pathname = "/e-shop";
  }
  return (
    <div className="main-page-wrapper">
      <header>
        <h1 className="header-main-page">{header}</h1>
        <div className="logo-wrapper">
          <img className="maama-logo2" src={MaamaLogo2} alt="Logo ma&ma" />
        </div>
        <Button onClick={goToEshop}>{t("home-header-button")}</Button>
      </header>

      {/* EXTRA SECTION: WE ARE OPEN */}

      {hasCollectionsLaunched && shouldHideCollectionsCountdonwn && maxOrdersForRunningCollections > 0 && (
        <MaxOrdersAlert />
      )}

      <>
        {hasCollectionsLaunched ? (
          <>
            {isNightshoppingTime
              ? (
                <NightBanner
                  heading="Nightshopping"
                  subtitle={t("collection-subtitle-nightshopping-1")}
                  button={<Button onClick={goToEshop}>{t("home-header-button")}</Button>}
                />
              ) : <ActiveCollectionsSection />
            }

            {/* {!hasNoNextCollectionsLaunchOrEnd && !shouldHideCollectionsCountdonwn && (
              // COUNTDOWN TO END
              <section className="section-countdown">
                <h2 className="section-countdown-h2">
                  {t("home-countdown-open-subheader")}
                </h2>
                <Countdown targetDate={collectionsEndTime.toISOString()} />
              </section>
            )} */}
          </>
        ) : (
          <>
            {!hasNoNextCollectionsLaunchOrEnd && (
              // EXTRA SECTION: COUNTDOWN TO LAUNCH
              <section className="section-countdown">
                <h1 className="section-countdown-h1">
                  {t("home-countdown-closed-header")}
                </h1>
                <h2 className="section-countdown-h2">
                  {" "}
                  {t("home-countdown-closed-subheader")}
                </h2>
                {/* Countdown component */}
                <Countdown targetDate={collectionsLaunchTime.toISOString()} />
              </section>
            )}
            {/* EXTRA SECTION: WE ARE CLOSED */}

            <section className="extra-section-we-are-closed center-signup-form">
              {/* Getting ready for launch */}
              <h1 className="header-main-page-extra-section-we-are-closed">
                {t("home-section-extra-we-are-closed-heading")}
              </h1>
              <div className="signup-form-wrapper">
                <MailchimpSignupForm />
              </div>
            </section>
          </>
        )}
      </>



      <section className="section-why-us grid grid-2-columns">
        <img
          className="product-pic1"
          src={ProductPic1}
          alt="A baby lying on a blanket made of muslin fabric"
        />

        <div className="paragraphs-large-display">
          {/* home-section-why-us-h3: Why us? */}
          <h3> {t("home-section-why-us-h3")}</h3>
          {/* home-section-why-us-h2: Because we're not your average fashion brand. */}
          <h2>{t("home-section-why-us-h2")}</h2>
          {/* home-section-why-us-paragraph-1: We're all about the handmade and taking it slow. Every stitch, every print, happens right here in our little workspace. No fancy factories, just us and our machines making everything happen. */}
          <p>{t("home-section-why-us-paragraph-1")}</p>
          {/* home-section-why-us-paragraph-2: We're all about being different. Our clothes are about embracing who you are. No labels, no boundaries – just clothes that make your children feel good. */}
          <p className="p-with-no-margin">
            {" "}
            {t("home-section-why-us-paragraph-2")}{" "}
          </p>
        </div>

        <div className="paragraphs-large-display">
          {/* home-section-why-us-paragraph-3: At ma&ma, we offer something different with every new collection. We are working on new patterns, new fabric types, and of course, new designs. We take preorders and then work hard on finishing up our creations so that you can enjoy them as soon as possible. */}
          <p>{t("home-section-why-us-paragraph-3")} </p>
          {/* home-section-why-us-paragraph-4: Your support means the world to us – not just because it helps us keep doing what we love, but because it shows that you appreciate the world of handmade. It is a small world and you get to be part of it. So, thank you for stopping by. We're excited to have you here, and we hope you find something that speaks to you. Welcome to the ma&ma family! */}
          <p>{t("home-section-why-us-paragraph-4")} </p>
        </div>

        <img
          className="product-pic2"
          src={ProductPic2}
          alt="A dress with garden design hanging on a wall"
        />
      </section>
      <section className="section-youtube-wrapper">
        <section className="section-youtube">
          <h3 className="youtube-h3">YOUTUBE</h3>
          <h2 className="youtube-h2">{t("home-section-youtube-h2")}</h2>
          <div className="youtube-video-wrapper">
            {" "}
            <IntroductionVideo />
          </div>
        </section>
      </section>
      {/* <section className="section-instagram">
        <h3>INSTAGRAM</h3>
        <h2>{t("home-section-instagram-h2")}</h2>
        <div className="grid grid-instagram-main-page">
          <InstagramEmbed
            url="https://www.instagram.com/p/CvE7B3ZKAg5/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CvE5bS6qs3F/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CvE2MfMqXL6/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C0YvEz4rQnI/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C4tqnw_IFRP/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C4uwd9wsm-O/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C4vRtusISFq/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
        </div>
      </section> */}
    </div>
  );
}
