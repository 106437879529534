import React, { useState, useEffect } from "react";
import "./Countdown.css";
import { t } from "../locale/locale";
import { hasNoNextCollectionsLaunchOrEnd } from "../helpers/helpers";

function shouldPluralize(count) {
  return count > 1 || count === 0;
}

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      // Prevent infinite loop if no relaunch is set
    } else if (!hasNoNextCollectionsLaunchOrEnd) {
      window.location.reload();
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  if (days || hours || minutes || seconds) {
    return (
      <div className="countdown-container">
        <div className="countdown-timer">
          <div style={{ display: 'flex' }}>
            {days}
            <span className="countdown-label">
              {t(`home-countdown-days${shouldPluralize(days) ? "-plural" : ""}`)}
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            {hours}
            <span className="countdown-label">
              {t(`home-countdown-hours${shouldPluralize(hours) ? "-plural" : ""}`)}
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            {minutes}
            <span className="countdown-label">
              {t(`home-countdown-minutes${shouldPluralize(minutes) ? "-plural" : ""}`)}
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            {seconds}
            <span className="countdown-label">
              {t(`home-countdown-seconds${shouldPluralize(seconds) ? "-plural" : ""}`)}
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default Countdown;
