import React from 'react';
import './ScrollDownIndicator.css'

export function ScollDownIndicator() {
    return (
        <div class="scroll-down-indicator">
            <div class="chevron"></div>
            <div class="chevron"></div>
            <div class="chevron"></div>
        </div>
    )
}