import React, { useEffect } from "react";
import "./Eshop.css";

// import { activeLanguage } from "../locale/locale";
import { t } from "../locale/locale";
import { isNightshoppingTime } from "../helpers/helpers"
import eshopClosed from "../img/product-pictures/March2024/IMG_4515.jpg";
import Banner from "../Components/Banner";
import Logo from "../img/logos/maama_logo_black.png";

import { hasCollectionsLaunched } from "../helpers/helpers";

export default function Eshop() {

  useEffect(() => {
    if (isNightshoppingTime) {
      window.location.pathname = '/collection-nightshopping';
    }
  }, []);

  function goToCollectionPatterns() {
    window.location.pathname = "/collection-patterns";
  }

  function goToCollectionColours() {
    window.location.pathname = "/collection-colours";
  }

  return (
    <div className="eshop-wrapper">
      {hasCollectionsLaunched ? (
        <section className="eshop-open">
          <div className="eshop-headers-wrapper">
            <div className="eshop-b-w-logo-wrapper">
              {" "}
              <img
                className="eshop-b-w-logo"
                src={Logo}
                alt="Black and White logo of ma&ma brand"
              />
            </div>{" "}
            <h2 className="eshop-header">{t("e-shop-collection-header")}</h2>
          </div>
          <div className="eshop-banner-wrapper">
            <Banner
              className="patterns"
              title={t("banner-title-collection-patterns")}
              subtitle={t("banner-subtitle-collection-patterns")}
              onClick={goToCollectionPatterns}
            />
            <Banner
              className="colors"
              title={t("banner-title-collection-colours")}
              subtitle={t("banner-subtitle-collection-colours")}
              onClick={goToCollectionColours}
            />
          </div>
        </section>
      ) : (
        <section className="eshop-closed">
          <h2>{t("e-shop-no-collection-header")}</h2>
          <p>{t("e-shop-no-collection-paragraph-0")}</p>
          <span>{t("e-shop-no-collection-paragraph-1")}</span>
          <a
            href="https://maama.no/next-e-shop-opening"
            rel="noopener noreferrer"
          >
            <span>{t("e-shop-no-collection-paragraph-2")}</span>
          </a>
          <span>{t("e-shop-no-collection-paragraph-3")}</span>
          <a
            href="https://www.instagram.com/maama_handmade/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("e-shop-no-collection-paragraph-4")}</span>
          </a>
          <span>{t("e-shop-no-collection-paragraph-5")}</span>
          <p>{t("e-shop-no-collection-paragraph-6")}</p>
          <img
            src={eshopClosed}
            alt="An empty clothes hanger"
            className="eshop-closed-image"
          />
        </section>
      )}
    </div>
  );
}
