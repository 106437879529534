import { useEffect } from "react";

// Since its too hard to find out when all iframes have loaded, we will wait an estimated time.
const timeToWaitBeforeAttemptingScroll = 1500;

export function useScrollToCollection(collections) {
  const getCollectionIdFromUrl = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get("collectionId");
  };

  useEffect(() => {
    console.log('useScrollToCollection', { collections });
    const collectionIds = collections.map((collection) => collection.id);

    const timeout = setTimeout(() => {
      const collectionIdFromUrl = getCollectionIdFromUrl();
      if (
        collectionIdFromUrl &&
        collectionIds.includes(Number(collectionIdFromUrl))
      ) {
        const collectionElement = document.getElementById(collectionIdFromUrl);
        if (collectionElement) {
          const offset = 30; // How much above the element you want to scroll
          const elementPosition =
            collectionElement.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    }, timeToWaitBeforeAttemptingScroll);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
