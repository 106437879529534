import React from "react";
import "./WhoAreWe.css";
import { t } from "../locale/locale";

import SistersPic1 from "../img/Photos/marketa_bara_2.png";
import SistersPic2 from "../img/Photos/marketa_bara.png";
import Logo1 from "../img/logos/maama_logo_whoarewe.png";

export default function WhoAreWe() {
  return (
    <div className="section-who-are-we">
      <section className="section-1-who-are-we grid grid-2-columns">
        {/* ********SECTION NUMBER 1********* */}

        <img
          className="sisters-pic1"
          src={SistersPic1}
          alt="Two sisters behind the clothing brand"
        />

        <div>
          {/* Who are we? */}
          <h3>{t("who-are-we-h3a")}</h3>
          {/* Our story isn't complicated */}
          <h2>{t("who-are-we-h2a")}</h2>
          {/* We're Bára and Markéta, two sisters who share a love for creating beautiful stuff. */}
          <p className="who-are-we-p">{t("who-are-we-paragraph-1")}</p>

          {/* These are some of the keywords which define our small business:  */}
          <p className="who-are-we-p">
            {t("who-are-we-paragraph-2")}
            {/* Handmade ♥ Slow Fashion ♥ Seasonal Surprises ♥ Gender-neutral. */}
            <span className="bold-spans">
              {t("who-are-we-paragraph-2-bold-spans")}
            </span>
          </p>

          <p className="p-with-no-margin">
            {/* We believe in the value of handmade; every piece in our collection is crafted by us. We're not into mass-producing things; we prefer taking our time and creating unique pieces that you won't find everywhere else.  */}
            {t("who-are-we-paragraph-3")}
          </p>
        </div>
      </section>

      {/* ********SECTION NUMBER 2********* */}
      <section className="section-2-who-are-we-wrapper">
        <div className="section-2-who-are-we grid grid-2-columns">
          <div>
            {/* Our signature fabric is ribbed jersey; we love how stretchy it is. We are constantly working on new products and designs.  */}
            <p className="who-are-we-p">{t("who-are-we-paragraph-4")}</p>

            <p className="who-are-we-p">
              {" "}
              {/* We don't categorize clothes by girls/boys. Why should clothes be just for boys or just for girls? We think everyone should wear whatever makes them happy. */}
              {t("who-are-we-paragraph-5")}
            </p>
            {/* The art of handmade creations */}
            <h3>{t("who-are-we-h3b")}</h3>
            {/* Labor of love */}
            <h2>{t("who-are-we-h2b")}</h2>
            {/* Making cool stuff by hand is what we love. Bara loves drawing designs and playing with colour palettes, while Marketa runs the business and is the hands behind sewing it all together. */}
            <p>{t("who-are-we-paragraph-6")}</p>
          </div>
          <img
            className="sisters-pic2"
            src={SistersPic2}
            alt="Two sisters behind the clothing brand"
          />{" "}
        </div>
      </section>

      {/* ********SECTION NUMBER 3********* */}
      <section className="section-3-who-are-we">
        <div>
          {/* Norway's unique tax system */}
          <p className="who-are-we-p">{t("who-are-we-paragraph-7")}</p>
          {/* Thank you */}
          <h1 className="section-3-who-are-we-h1">{t("who-are-we-h1")}</h1>
          {/* for appreciating the craftsmanship, dedication, and perseverance behind each item. We want you to be part of our journey, feeling the care we put into everything we make. */}
          <p className="who-are-we-p">{t("who-are-we-paragraph-8")}</p>
        </div>
        <img className="who-are-we-logo" src={Logo1} alt="The brand logo" />
      </section>
    </div>
  );
}
