import React from "react";
import "./ImportantInformation.css";
import { t } from "../locale/locale";

export default function TermsOfService() {
  return (
    <div className="section-important-information">
      <h1>{t("terms-of-service-h1")}</h1>
      <div className="paragraph-section-important-information">
        {/* Business Details */}
        <div className="paragraph-important-information">
          {t("terms-of-service-paragraph-business-details")}
        </div>
        {/* Foreword */}
        <div className="paragraph-important-information">
          {t("terms-of-service-paragraph-foreword")}
        </div>

        {/* Paragraphs */}
        {/* General */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-1-title")}
          </div>
          {t("terms-of-service-paragraph-1")}
        </div>

        {/* Products */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-2-title")}
          </div>
          {t("terms-of-service-paragraph-2")}
        </div>

        {/* Ordering and Payment */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-3-title")}
          </div>
          {t("terms-of-service-paragraph-3")}
        </div>

        {/* Order Process */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-4-title")}
          </div>
          {t("terms-of-service-paragraph-4")}
        </div>

        {/* Size Recommendations, E-shop Availability, and Delivery */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-5-title")}
          </div>
          {t("terms-of-service-paragraph-5")}
        </div>

        {/* Intellectual Property */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-6-title")}
          </div>
          {t("terms-of-service-paragraph-6")}
        </div>

        {/* Governing Law */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-7-title")}
          </div>
          {t("terms-of-service-paragraph-7")}
        </div>

        {/* Changes to Terms */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-8-title")}
          </div>
          {t("terms-of-service-paragraph-8")}
        </div>

        {/* Contact Information */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-9-title")}
          </div>
          {t("terms-of-service-paragraph-9")}
        </div>

        {/* Liability Limitation */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-10-title")}
          </div>
          {t("terms-of-service-paragraph-10")}
        </div>

        {/* User Conduct */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-11-title")}
          </div>
          {t("terms-of-service-paragraph-11")}
        </div>

        {/* Privacy Policy Reference */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-12-title")}
          </div>
          {t("terms-of-service-paragraph-12")}
        </div>

        {/* Dispute Resolution */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-13-title")}
          </div>
          {t("terms-of-service-paragraph-13")}
        </div>

        {/* Termination Clause */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-14-title")}
          </div>
          {t("terms-of-service-paragraph-14")}
        </div>

        {/* Amendments and Waiver */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-15-title")}
          </div>
          {t("terms-of-service-paragraph-15")}
        </div>

        {/* Severability */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-16-title")}
          </div>
          {t("terms-of-service-paragraph-16")}
        </div>

        {/* Disclaimer */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-17-title")}
          </div>
          {t("terms-of-service-paragraph-17")}
        </div>

        {/* Feedback and Submissions Policy */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-18-title")}
          </div>
          {t("terms-of-service-paragraph-18")}
        </div>

        {/* Accessibility */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-19-title")}
          </div>
          {t("terms-of-service-paragraph-19")}
        </div>

        {/* International Use */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-20-title")}
          </div>
          {t("terms-of-service-paragraph-20")}
        </div>

        {/* Compliance with Local Laws */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-21-title")}
          </div>
          {t("terms-of-service-paragraph-21")}
        </div>

        {/* Age Restriction Clarification */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("terms-of-service-paragraph-22-title")}
          </div>
          {t("terms-of-service-paragraph-22")}
        </div>
      </div>
    </div>
  );
}
