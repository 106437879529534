import React from "react";
import { setLanguage, activeLanguage, t } from "../locale/locale";

export default function LanguageDropDown() {
  function toggleDropdown() {
    const dropdownElement = document.getElementById("dropdown-menu");
    if (dropdownElement.classList.contains("show")) {
      dropdownElement.classList.remove("show");
    } else {
      dropdownElement.classList.add("show");
    }
  }

  function returnCorrectFlag() {
    var flagUk = "🇬🇧";
    var flagNo = "🇳🇴";
    if (activeLanguage === "en") {
      return flagUk;
    } else if (activeLanguage === "no") {
      return flagNo;
    } else {
      throw new Error("Invalid active language");
    }
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        onClick={toggleDropdown}
      >
        {returnCorrectFlag()} {t("footer-language-button")}
      </button>
      <div
        id="dropdown-menu"
        className="dropdown-menu"
        aria-labelledby="dropdownMenu2"
      >
        <button
          className="dropdown-item"
          type="button"
          onClick={() => setLanguage("en")}
        >
          🇬🇧 English
        </button>
        <button
          className="dropdown-item"
          type="button"
          onClick={() => setLanguage("no")}
        >
          🇳🇴 Norsk
        </button>
      </div>
    </div>
  );
}
