import React from "react";
import { t } from "../locale/locale";

import "./Banner.css";

import Button from "./Button";

export default function Banner(props) {
  // This code is checking if the className property of the props object is neither "patterns" nor "colors." If it's not one of these values, it throws an error indicating that only "patterns" and "colors" are valid
  if (props.className !== "patterns" && props.className !== "colors") {
    throw new Error(
      'Only "patterns" and "colors" are valid className values. If you want new images, add new styles and update this condition'
    );
  }
  return (
    <div className={`square-banner ${props.className}`}>
      <div className="banner-text">
        <h3>{props.title}</h3>
        <p>{props.subtitle}</p>
        <Button onClick={props.onClick}>{t("collection-button")}</Button>
      </div>
    </div>
  );
}
