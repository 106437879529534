import React from "react";
import "./SizeChart.css";
import { t } from "../locale/locale";

import LeggingsMedSeler from "../img/size-chart/Leggings_med_sele.png";
import RomperOli from "../img/size-chart/Romper.png";
import Beanie from "../img/size-chart/Beanie.png"

export default function SizeChart() {
  return (
    <div>
      <h1 className="size-chart-h1">{t("size-chart-main-title")}</h1>
      <p className="size-chart-foreword">{t("size-chart-foreword")}</p>
      <section className="section-romper">
        <h2 className="size-chart-h2">{t("size-chart-product-romper")}</h2>
        <img className="size-chart-img" src={RomperOli} alt="Overall" />
      </section>
      <section className="section-leggings">
        <h2 className="size-chart-h2">
          {t("size-chart-product-leggings-traky")}
        </h2>
        <img className="size-chart-img" src={LeggingsMedSeler} alt="Leggings" />
      </section>
      <section className="section-beanie last-section">
        <h2 className="size-chart-h2">
          {t("size-chart-product-beanie")}
        </h2>
        <img className="size-chart-img" src={Beanie} alt="Beanie" />
      </section>
    </div>
  );
}
