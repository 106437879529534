import React from 'react';
import './SeagullsBanner.css';

const SeagullsBanner = () => {
    return (
        <div className="banner-wrapper">
            <div className="scene-wrapper">
                <svg className="hidden" xmlns="http://www.w3.org/2000/svg">
                    <symbol viewBox="0 0 2744 489" id="water-waves" preserveAspectRatio="xMinYMin">
                        <path d="M0 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489H0V0zm343 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489H343V0zm343 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489H686V0zm343 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489h-343V0zm343 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489h-343V0zm343 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489h-343V0zm343 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489h-343V0zm343 0s39.5 29.6 79 29.6c41.2 0 82-29.6 82-29.6s50.2 29.6 94 29.6c45.7 0 88-29.6 88-29.6v489h-343V0z" fillRule="evenodd" />
                    </symbol>
                </svg>
                <svg className="water__wave-back"><use xlinkHref="#water-waves" /></svg>
                <svg className="seagull" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 133" preserveAspectRatio="xMinYMid">
                    <g id="Page-1" fill="none" fillRule="evenodd">
                        <g id="seagull" transform="translate(-48 -51)">
                            <path id="head-feathers" fill="#000" stroke="#000" d="M116 59c-.3 4.2 2 10 1 9-1.3-1.3-3.5-5.3-3.4-9.2 0-2 .7-4 2.4-5.8 2.7-2.7.3 1 0 6zm5 .3c-2 3.8-2.4 9.8-2.8 8.6-.7-1.8-1-6.3.6-10 1-1.7 2.3-3.3 4.5-4.2 3.6-1.4 0 1-2.4 5.5z" />
                            <path id="wing" fill="#394963" d="M128 142s18 1 18 12-10 17-15 17-21 4-32-25c26 10 29-4 29-4z" />
                            <path id="tail-feather" fill="#4A5E76" d="M73 142l-25-28 6-1 1-5 6 1 4-4 4 2 3-3 3 35z" />
                            <path id="body" fill="#FFF" stroke="#FFF" d="M111 143l-32-1s-2-3-5-3-2 11-2 11 5 37 37 33 33-26 33-26 1-16 1-31-2-30-2-30-1-23-11-26-18 0-18 0l-1 73z" />
                            <ellipse id="Oval-1" cx="125" cy="85" fill="#FFFDD9" stroke="#2B2B2B" rx="7" ry="5" />
                            <path id="Oval-2" fill="#FFFDD9" stroke="#2B2B2B" d="M139 89c2.8 0 5-1.8 5-4s-2.2-4-5-4-5 1.8-5 4 2.2 4 5 4z" />
                            <circle id="left-iris" cx="122.5" cy="85.5" r="1.5" fill="#010101" stroke="#000" />
                            <circle id="right-iris" cx="141.5" cy="84.5" r="1.5" fill="#000" stroke="#000" />
                            <path id="wing" fill="#394963" d="M105 145s18 1 18 12-10 17-15 17-21 4-32-25c26 10 29-4 29-4z" />
                            <path id="mouth" fill="#E0AC1A" stroke="#715410" d="M129.7 99.4c-2-.8-2-1.4.3-1.4h17s3.2 7.5 2 8c-1 .5-19.3-6.6-19.3-6.6z" />
                            <path id="beak" fill="#E0AC1A" stroke="#715410" d="M122.7 95c-1 .6-1 1 .2 1h33c17 0 23 0 31 6 3-3-8-11-8-11s-10-6-15-6-14 6-17 6c0 0-8 1-10 0s-4-4-4-4c-5 6-10.3 8-10.3 8z" />
                        </g>
                    </g>
                </svg>
                <svg className="water__wave-front"><use xlinkHref="#water-waves" /></svg>
                <div className="water__wave-front--fill"></div>
            </div>
        </div>
    );
};

export default SeagullsBanner;

