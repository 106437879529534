import React from "react";
import "./Footer.css";
import LanguageDropDown from "../Components/LanguageDropDown";
import { t } from "../locale/locale";
import { hasCollectionsLaunched, shouldHideCollectionsCountdonwn } from "../helpers/helpers";

import FacebookIcon from "../img/icons/icons8-facebook-circled-80.png";
import InstagramIcon from "../img/icons/icons8-instagram-80.png";
import MamaLogoColours from "../img/logos/maama_logo_colours.png";
import MailchimpSignupForm from "./MailchimpSignUpForm";

import VisaIcon from "../img/icons/payment icons/visa.png";
import MasterCardIcon from "../img/icons/payment icons/mastercard.png";
import AmexIcon from "../img/icons/payment icons/amex.png";
import ApplePayIcon from "../img/icons/payment icons/apple_pay.png";
import VippsIcon from "../img/icons/payment icons/vipps.png"

export default function Footer() {
  return (
    <div className="footer-container">
      <footer className="grid grid-3-columns footer">
        {/* Section 1 of Footer - About us */}

        <section className="footer-section-about">
          <h3>{t("footer-about-us-heading")}</h3>
          <ul>
            <li>
              <a className="footer-link" href="/who-am-i">
                {t("footer-about-us-link-who-am-i")}
              </a>
            </li>
            <li>
              <a className="footer-link" href="/order-process">
                {t("footer-about-us-link-order-process")}
              </a>
            </li>
            {!shouldHideCollectionsCountdonwn && (
              <li>
                <a className="footer-link" href="/next-e-shop-opening">
                  {hasCollectionsLaunched
                    ? t("footer-about-us-link-next-eshop-closing")
                    : t("footer-about-us-link-next-eshop-opening")}
                </a>
              </li>
            )}
          </ul>
        </section>

        {/* Section 2 of Footer - Important information */}

        <section className="footer-section-important">
          <h3> {t("footer-important-information-heading")}</h3>
          <ul>
            <li>
              <a className="footer-link" href="/terms-of-service">
                {t("footer-important-information-link-terms-of-service")}
              </a>
            </li>
            <li>
              <a className="footer-link" href="/privacy-policy">
                {t("footer-important-information-link-privacy-policy")}
              </a>
            </li>
            <li>
              <a className="footer-link" href="/return-policy">
                {t("footer-important-information-link-return-policy")}
              </a>
            </li>
          </ul>
        </section>

        {/* Section 3 of Footer - Contact us + Social media icons*/}

        <section className="footer-section-contact">
          <h3> {t("footer-contact-us-heading")}</h3>
          <ul>
            <li>
              <a className="footer-link" href="/contact-us">
                {t("footer-contact-us-link-contact-page")}
              </a>
            </li>
            <li>
              <section className="footer-section-social">
                <div className="social-media-icons">
                  {" "}
                  <a
                    href="https://www.instagram.com/maama_handmade/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-icon"
                      src={InstagramIcon}
                      alt="Instagram icon"
                    />
                  </a>
                </div>
                <div className="social-media-icons">
                  <a
                    href="https://facebook.com/maama.no.handmade"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img
                      className="footer-icon"
                      src={FacebookIcon}
                      alt="Facebook icon"
                    />
                  </a>
                </div>
              </section>
            </li>
          </ul>
        </section>
      </footer>
      {/* Section 4 of Footer - Language Dropdown*/}

      <div className="language-dropdown">
        <LanguageDropDown />
      </div>
      {/* Section 5 of Footer - Sign up form */}
      <MailchimpSignupForm />

      {/* Section 6 of Footer - Payment icons */}
      <div>
        <section className="payment-icons">
          <img className="payment-icon" src={VisaIcon} alt="Visa payment method" />
          <img
            className="payment-icon"
            src={MasterCardIcon}
            alt="Master Card payment method"
          />
          <img className="payment-icon" src={AmexIcon} alt="Amex payment method" />
          <img
            className="payment-icon"
            src={ApplePayIcon}
            alt="Apple Pay payment method"
          /> <img
            className="payment-icon"
            src={VippsIcon}
            alt="Vipps payment method"
          />
        </section>
      </div>

      {/* Section 7 of Footer - Disclaimer */}

      <section className="disclaimer-section">
        <p className="disclaimer">
          {t("footer-disclaimer-created-by")}
          <img className="logo-black" src={MamaLogoColours} alt="" />
          {t("footer-disclaimer-year")} |{" "}
        </p>
        <p className="attribution">
          <a
            className="disclaimer-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://icons8.com/icon/79137/facebook-circled"
          >
            Facebook{" "}
          </a>
          &{" "}
          <a
            className="disclaimer-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://icons8.com/icon/42328/instagram"
          >
            Instragram
          </a>{" "}
          Circled icon by{" "}
          <a
            className="disclaimer-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://icons8.com"
          >
            Icons8
          </a>
        </p>
      </section>
    </div>
  );
}
