import React, { useEffect } from "react";
import "./NextOpening.css";
import { t } from "../locale/locale";

import Countdown from "../Components/Countdown";
import {
  collectionsLaunchTime,
  collectionsEndTime,
  hasCollectionsLaunched,
  hasNoNextCollectionsLaunchOrEnd,
  shouldHideCollectionsCountdonwn,
} from "../helpers/helpers";

export default function NextOpening() {
  // Escape case for if someone comes in here but should not see any countdown to collections end timer
  useEffect(() => {
    if (shouldHideCollectionsCountdonwn) {
      window.location.href = '/';
    }
  });
  if (shouldHideCollectionsCountdonwn) {
    return null;
  }

  if (hasNoNextCollectionsLaunchOrEnd) {
    return (
      <div className="paragraph-section-next-opening">
        <h2 className="next-opening-not-sure">{t("next-opening-not-sure")}</h2>
      </div>
    );
  }

  return (
    <div className="paragraph-section-next-opening">
      {hasCollectionsLaunched ? (
        <>
          <h2 className="time-till-open-h2">
            {t("home-countdown-open-subheader")}
          </h2>
          <Countdown targetDate={collectionsEndTime.toISOString()} />
        </>
      ) : (
        <>
          <h1 className="next-opening-h1">{t("next-opening-h1")}</h1>
          <p>
            {t("next-opening-paragraph")}{" "}
            <span className="next-opening-date">{t("next-opening-date")}</span>{" "}
            <span className="next-opening-time">{t("next-opening-time")}</span>
          </p>
          <Countdown targetDate={collectionsLaunchTime.toISOString()} />
        </>
      )}
    </div>
  );
}
