import React from "react";
import "./WhoAmI.css";
import { t } from "../locale/locale";

import SistersPic1 from "../img/Photos/marketa_bara_2.png";
import MarketaAndViktor1 from "../img/familypics/IMG_1029.jpg";
import MeAndEmily1 from "../img/familypics/meandemily.jpg";
import Logo2 from "../img/logos/maama_logo_colours.png";

export default function WhoAmI() {
  return (
    <div className="section-who-am-i">
      <section className="section-1-who-am-i grid grid-2-columns">
        {/* ********SECTION NUMBER 1********* */}

        <img
          className="product-pic1"
          src={SistersPic1}
          alt="A baby lying on a blanket made of muslin fabric"
        />

        <div>
          {/* Who are we? */}
          <h3>{t("who-am-i-h3a")}</h3>
          {/* Our story isn't complicated */}
          <h2>{t("who-am-i-h2a")}</h2>
          {/* We're Bára and Markéta, two sisters who share a love for creating beautiful stuff. */}
          <p className="who-am-i-p">{t("who-am-i-paragraph-1")}</p>

          {/* These are some of the keywords which define our small business:  */}
          <p className="who-am-i-p">{t("who-am-i-paragraph-2")}</p>
        </div>
      </section>

      {/* ********SECTION NUMBER 2********* */}
      <section className="section-2-who-am-i-wrapper">
        <div className="section-2-who-am-i grid grid-2-columns">
          <div>
            <p className="p-with-no-margin">
              {/* We believe in the value of handmade; every piece in our collection is crafted by us. We're not into mass-producing things; we prefer taking our time and creating unique pieces that you won't find everywhere else.  */}
              {t("who-am-i-paragraph-3")}
            </p>
            {/* Our signature fabric is ribbed jersey; we love how stretchy it is. We are constantly working on new products and designs.  */}
            <p className="who-am-i-p">{t("who-am-i-paragraph-4")}</p>
          </div>
          <img
            className="product-pic2"
            src={MarketaAndViktor1}
            alt="Marketa and Viktor pregnancy photoshoot"
          />{" "}
        </div>
      </section>

      {/* ********SECTION NUMBER 3********* */}
      <section className="section-3-who-am-i grid grid-2-columns">
        <img
          className="product-pic1"
          src={MeAndEmily1}
          alt="A baby lying on a blanket made of muslin fabric"
        />

        <div>
          <p className="who-am-i-p">
            {" "}
            {/* We don't categorize clothes by girls/boys. Why should clothes be just for boys or just for girls? We think everyone should wear whatever makes them happy. */}
            {t("who-am-i-paragraph-5")}
          </p>
          {/* The art of handmade creations */}
          <h3>{t("who-am-i-h3b")}</h3>
          {/* Labor of love */}
          <h2>{t("who-am-i-h2b")}</h2>
          {/* Making cool stuff by hand is what we love. Bara loves drawing designs and playing with colour palettes, while Marketa runs the business and is the hands behind sewing it all together. */}
          <p>{t("who-am-i-paragraph-6")}</p>
        </div>
      </section>

      {/* ********SECTION NUMBER 4********* */}
      <section className="section-4-who-am-i-wrapper">
        <div className="section-4-who-am-i">
          <div>
            {/* Norway's unique tax system */}
            <p className="who-am-i-p">{t("who-am-i-paragraph-7")}</p>
            {/* Thank you */}
            <h1 className="section-4-who-am-i-h1">{t("who-am-i-h1")}</h1>
            {/* for appreciating the craftsmanship, dedication, and perseverance behind each item. We want you to be part of our journey, feeling the care we put into everything we make. */}
            <p className="who-am-i-p">{t("who-am-i-paragraph-8")}</p>
          </div>
          <img className="who-am-i-logo" src={Logo2} alt="Logo of our brand" />
        </div>
      </section>

      {/* ********SECTION NUMBER 5********* */}
      <section className="section-3-who-am-i">
        <img className="who-am-i-logo" src={Logo2} alt="Logo of our brand" />
      </section>
    </div>
  );
}
