import React from "react";

import {
  collectionOptionBasicEn,
  collectionOptionBasicNo,
} from "../../Components/ShopifySetup/ShopifyCollection";
import { activeLanguage } from "../../locale/locale";
import { useScreenSize } from "../../helpers/useScreenSize";
import { t } from "../../locale/locale";
import Collection from "../../Components/Collection";
import { useScrollToCollection } from "./useScrollToCollection";
import PatternsBgVideo from '../../video/eshop-video-bg-patterns.mp4'

export default function CollectionPatterns() {
  const screenSize = useScreenSize();

  const collections = [
    { id: 568837505297, title: t("collection-patterns-id-1-title") },
    { id: 576769229073, title: t("collection-patterns-id-2-title") },
    { id: 561864442129, title: t("collection-patterns-id-3-title") },
    { id: 561864605969, title: t("collection-patterns-id-4-title") },
    { id: 576776110353, title: t("collection-patterns-id-5-title"), subtitle: screenSize.mobile ? t("collection-patterns-id-3-subtitle") : undefined },
  ];

  useScrollToCollection(collections);

  function returnCorrectButton() {
    if (activeLanguage === "en") {
      return collectionOptionBasicEn;
    } else if (activeLanguage === "no") {
      return collectionOptionBasicNo;
    } else {
      throw new Error("Invalid active language");
    }
  }
  return (
    <div>
      <Collection
        videoBackground={PatternsBgVideo}
        // header={t("collection-header")}
        name={t("collection-name-patterns-1")}
        subtitle={t("collection-subtitle-patterns-1")}
        collections={collections}
        options={returnCorrectButton()}
        className="eshop-collection"
        instagramIds={[
          "DAPtcOJJD3k",
          "DAP7MktOULi",
          "DAQCEtyq2V9",
          "DAQP0qXOvDr",
          "DAQWqAEJsxk",
          "DAQkZT3CVbj",
          "DAQyJFgClpD",
          "DAQ5AAuv8o8",
          "DARGt35M7YF",
          "DARNmM6PxlJ",
          "DAU3C5lNha2",
          "DAVExaviyjI",
          "DAVShYiM_rh",
          "DAVZYmftAg6"
        ]}
      />
    </div>
  );
}
