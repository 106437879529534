import React from "react";
import "./ImportantInformation.css";
import { t } from "../locale/locale";

export default function ReturnPolicy() {
  return (
    <div className="section-important-information">
      <h1>{t("return-policy-h1")}</h1>
      <div className="paragraph-section-important-information">
        {/* Foreword */}
        <div className="paragraph-important-information">
          {t("return-policy-paragraph-foreword")}
        </div>

        {/* Paragraph nr. 1 - Foreword */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("return-policy-paragraph-1-title")}
          </div>
          {t("return-policy-paragraph-1")}
        </div>

        {/* Paragraph nr. 2 - Returns and Exchanges */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("return-policy-paragraph-2-title")}
          </div>
          {t("return-policy-paragraph-2")}
        </div>

        {/* Paragraph nr. 3 - Shipping */}
        <div className="paragraph-important-information">
          <div className="bold-titles">
            {t("return-policy-paragraph-3-title")}
          </div>
          {t("return-policy-paragraph-3")}
        </div>
      </div>
    </div>
  );
}
