export const isNightshoppingTime = false;
export const canShowCollectionsEndTimeCountdown = true;
export const freeDeliveryOnOrdersAboveNOK = 0; // Set to 0 to not show the message
export const maxOrdersForRunningCollections = 30;
export const collectionsLaunchTime = getLaunchTime() || new Date("2024-10-04T10:00:00+02:00");
export const collectionsEndTime = getEndTime() || new Date("2024-10-08T15:20:00+02:00");


export function setLaunchTime(date) {
  localStorage.setItem('collectionsLaunchTime', date.toISOString());
}

export function setEndTime(date) {
  localStorage.setItem('collectionsEndTime', date.toISOString());
}

export function getLaunchTime() {
  const time = localStorage.getItem('collectionsLaunchTime');
  return time ? new Date(time) : null;
}

export function getEndTime() {
  const time = localStorage.getItem('collectionsEndTime');
  return time ? new Date(time) : null;
}

export function isPastDate(inputDate) {
  return new Date() > inputDate;
}

export function isBeforeDate(inputDate) {
  return new Date() < inputDate;
}

export const hasCollectionsLaunched =
  isPastDate(collectionsLaunchTime) && isBeforeDate(collectionsEndTime);

export const shouldHideCollectionsCountdonwn = hasCollectionsLaunched && !canShowCollectionsEndTimeCountdown;

// Protection against if you forget to update launch/end time
export const hasNoNextCollectionsLaunchOrEnd =
  isPastDate(collectionsLaunchTime) && isPastDate(collectionsEndTime);

window.maama = {
  resetStoreTime: function () {
    localStorage.removeItem('collectionsLaunchTime');
    localStorage.removeItem('collectionsEndTime');
    window.location.reload();
  },
  openStore: function () {
    const now = new Date();
    setLaunchTime(now);
    this.closeStoreIn(9999999);
    window.location.reload();
  },
  openStoreIn: function (minutes) {
    const now = new Date();
    const futureTime = new Date(now.getTime() + minutes * 60000);
    setLaunchTime(futureTime);
    window.location.reload();
  },
  closeStore: function () {
    const now = new Date();
    setEndTime(now);
    window.location.reload();
  },
  closeStoreIn: function (minutes) {
    const now = new Date();
    const futureTime = new Date(now.getTime() + minutes * 60000);
    setEndTime(futureTime);
    window.location.reload();
  }
};