import React, { useEffect } from "react";

import {
  collectionOptionBasicEn,
  collectionOptionBasicNo,
} from "../../Components/ShopifySetup/ShopifyCollection";
import { activeLanguage } from "../../locale/locale";
import { t } from "../../locale/locale";
import Collection from "../../Components/Collection";
import { useScrollToCollection } from "./useScrollToCollection";
import { NightBanner } from "../../Components/NightBanner";
import { hasCollectionsLaunched, isNightshoppingTime } from "../../helpers/helpers";
import eshopClosed from "../../img/product-pictures/March2024/IMG_4515.jpg";

const collections = [
  { id: 519538835729, title: t("collection-nightshopping-id-1-title") },
  { id: 519354646801, title: t("collection-nightshopping-id-2-title") },
];

export default function CollectionNightshopping() {
  useScrollToCollection(collections);

  useEffect(() => {
    if (!isNightshoppingTime) {
      window.location.pathname = '/e-shop';
    }
  }, []);

  useEffect(() => {
    if (!isNightshoppingTime) {
      window.location.href = '/';
    }
  }, [])

  function returnCorrectButton() {
    if (activeLanguage === "en") {
      return collectionOptionBasicEn;
    } else if (activeLanguage === "no") {
      return collectionOptionBasicNo;
    } else {
      throw new Error("Invalid active language");
    }
  }
  return (
    <div>
      {hasCollectionsLaunched ? (
        <>
          <NightBanner
            heading={t("collection-name-nightshopping-1")}
            subtitle={t("collection-subtitle-nightshopping-1")}
          />
          <Collection
            collections={collections}
            options={returnCorrectButton()}
            className="eshop-collection"
            instagramIds={[
              "C7CkqsJropM",
              "C66q6pWhLmc",
              "C7BtNXQL0jU",
              "C68CKvwsxl7",
              "C7AE9kdrea6",
              "C66_4BMMYhK",
            ]}
          />

        </>
      ) : (
        <section className="eshop-closed">
          <h2>{t("e-shop-no-collection-header")}</h2>
          <span>{t("e-shop-no-collection-paragraph-1")}</span>
          <a
            href="https://maama.no/next-e-shop-opening"
            rel="noopener noreferrer"
          >
            <span>{t("e-shop-no-collection-paragraph-2")}</span>
          </a>
          <span>{t("e-shop-no-collection-paragraph-3")}</span>
          <a
            href="https://www.instagram.com/maama_handmade/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("e-shop-no-collection-paragraph-4")}</span>
          </a>
          <span>{t("e-shop-no-collection-paragraph-5")}</span>
          <p>{t("e-shop-no-collection-paragraph-6")}</p>
          <img
            src={eshopClosed}
            alt="An empty clothes hanger"
            className="eshop-closed-image"
          />
        </section>
      )}
    </div>
  );
}
