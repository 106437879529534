import React from "react";
import "./Collection.css";

import Button from "./Button";
import ShopifyCollection, {
  collectionOptionBasicEn,
  collectionOptionBasicNo,
} from "./ShopifySetup/ShopifyCollection";
import { activeLanguage } from "../locale/locale";
import { t } from "../locale/locale";
import { InstagramEmbed } from "react-social-media-embed";
import { useScreenSize } from "../helpers/useScreenSize";
import { ScollDownIndicator } from "./ScrollDownIndicator";

export default function Collection(props) {
  const screenSize = useScreenSize();
  const shouldWrapInstagramPosts = screenSize.width < 960;

  function returnCorrectButton() {
    if (activeLanguage === "en") {
      return collectionOptionBasicEn;
    } else if (activeLanguage === "no") {
      return collectionOptionBasicNo;
    } else {
      throw new Error("Invalid active language");
    }
  }
  function goToEshop() {
    window.location.pathname = "/e-shop";
  }
  return (
    <div>
      {props.header && <h2 className="collection-component-header">{props.header}</h2>}
      <section className="collection-component-headers-wrapper">
        {/* Currently open collection */}
        <div className="collection-video-wrapper">
          <div className="collection-video-foreground" style={{ position: props.videoBackground && screenSize.mobile ? 'absolute' : 'initial' }}>
            {props.name && <h1 className="collection-component-name">{props.name}</h1>}
            {props.subtitle && (
              <h6 className="collection-component-subtitle">{props.subtitle}</h6>
            )}
            {props.videoBackground && (
              <div className="collection-component-scroll-indicator-wrapper">
                <ScollDownIndicator />
              </div>
            )}
            <div className="collection-video-foreground-gradient" />
          </div>
          <video className="collection-video-background" playsInline autoPlay muted loop style={props.videoBackground ? {} : { width: 0 }}>
            <source src={props.videoBackground} type="video/mp4" />
          </video>
        </div>
      </section>
      <section className="collection-component-section-1">
        <div>
          {props.collections.map((collection) => (
            <div id={collection.id} className="collection-wrapper">
              <h3 className="collection-title">{collection.title}</h3>
              <h5 className="collection-subtitle">{collection.subtitle}</h5>
              <ShopifyCollection
                name={`c-${collection.id}`}
                id={collection.id}
                options={returnCorrectButton()}
              />
            </div>
          ))}
        </div>
      </section>
      <section className="collection-component-section-2">
        <Button onClick={goToEshop}>{t("collection-all-button")}</Button>
        <h3 className="collection-component-h3">
          {/* About this collection */}
          {t("collection-section-2-h3")}
        </h3>
        <ul className="collection-component-ul">
          <li>{t("collection-section-2-li-1")}</li>
          <li>{t("collection-section-2-li-2")}</li>
          <li>{t("collection-section-2-li-3")}</li>
        </ul>
      </section>
      <section className="collection-component-section-3">
        <h4 className="collection-component-h4">INSTAGRAM</h4>
        <h5 className="collection-component-h5">
          {t("collection-section-3-h5")}
        </h5>
        <div className="grid grid-instagram-collection">
          {props.instagramIds.map(function (instagramId, index) {
            if (shouldWrapInstagramPosts && index > 0) {
              return null;
            }
            return (
              <InstagramEmbed
                url={`https://www.instagram.com/p/${instagramId}/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==`}
                width={shouldWrapInstagramPosts ? "100%" : 328}
                style={shouldWrapInstagramPosts ? { maxWidth: 550, margin: "0 auto" } : {}}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}
