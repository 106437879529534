import React from "react";

import {
  collectionOptionBasicEn,
  collectionOptionBasicNo,
} from "../../Components/ShopifySetup/ShopifyCollection";
import { activeLanguage } from "../../locale/locale";
import { t } from "../../locale/locale";
import Collection from "../../Components/Collection";
import { useScrollToCollection } from "./useScrollToCollection";
import { hasCollectionsLaunched } from "../../helpers/helpers";
import eshopClosed from "../../img/product-pictures/March2024/IMG_4515.jpg";
import seagullGif from "../../img/gifs/seagull.gif"
import "../../Components/Gifs.css"
import SeagullsBanner from "../../Components/SeagullsBanner";

const collections = [
  { id: 519538835729, title: t("collection-nightshopping-id-1-title") },
  { id: 519354646801, title: t("collection-nightshopping-id-2-title") },
];

export default function CollectionNightshopping() {
  useScrollToCollection(collections);

  function returnCorrectButton() {
    if (activeLanguage === "en") {
      return collectionOptionBasicEn;
    } else if (activeLanguage === "no") {
      return collectionOptionBasicNo;
    } else {
      throw new Error("Invalid active language");
    }
  }
  return (
    <div>
      {hasCollectionsLaunched ? (
        <>
         <SeagullsBanner />
          <Collection
            collections={collections}
            options={returnCorrectButton()}
            className="eshop-collection"
            instagramIds={[
             "C7CkqsJropM",
          
            ]}
          />
           <img
            src={seagullGif}
            alt="A seagull"
            className="seagull-gif"
          />
     

        </>
      ) : (
        <section className="eshop-closed">
          <h2>{t("e-shop-no-collection-header")}</h2>
          <span>{t("e-shop-no-collection-paragraph-1")}</span>
          <a
            href="https://maama.no/next-e-shop-opening"
            rel="noopener noreferrer"
          >
            <span>{t("e-shop-no-collection-paragraph-2")}</span>
          </a>
          <span>{t("e-shop-no-collection-paragraph-3")}</span>
          <a
            href="https://www.instagram.com/maama_handmade/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("e-shop-no-collection-paragraph-4")}</span>
          </a>
          <span>{t("e-shop-no-collection-paragraph-5")}</span>
          <p>{t("e-shop-no-collection-paragraph-6")}</p>
          <img
            src={eshopClosed}
            alt="An empty clothes hanger"
            className="eshop-closed-image"
          />
        </section>
      )}
    </div>
  );
}
