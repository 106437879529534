import { useEffect, useState } from 'react';

export function useScreenSize() {
    const [breakpoint, setBreakPoint] = useState('mobile');
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        if (0 < windowSize.width && windowSize.width < 960 + 1) {
            setBreakPoint('mobile');
        } else {
            setBreakPoint('desktop')
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [windowSize.width]);

    return {
        mobile: breakpoint === 'mobile',
        desktop: breakpoint === 'desktop',
        width: windowSize.width
    };
};
