import React, { useState, useRef, useEffect, useCallback } from "react";
import "./ShopifyCollection.css";
import fetchIntercept from "fetch-intercept";

// Intercepts and transforms responses from shopify used by the ShopifyBuy embeddable
fetchIntercept.register({
  response: async (response) => {
    try {
      const json = await response
        .json()
        .then((res) => {
          const isCollectionResponse =
            res?.data?.node?.__typename === "Collection";
          if (!isCollectionResponse) {
            return res;
          }
          console.log("Intercepting Shopify collection");

          return {
            ...res,
            data: {
              ...res.data,
              node: {
                ...res.data.node,
                products: {
                  ...res.data.node.products,
                  edges: res.data.node.products.edges
                    .map((product) => {
                      const options = product.node.options;
                      const variants = product.node.variants.edges;
                      const images = product.node.images.edges;

                      // Remove variants which are not available
                      const filteredVariants = variants.filter((variant) => {
                        const available = variant.node.available;
                        return (
                          typeof available !== "undefined" && available === true
                        );
                      });

                      // Remove option values which has a title that contains a unavailble variant
                      const correctedOptions = options.map((option) => {
                        const filteredOptionValues = option.values.filter(
                          (variantValue) =>
                            filteredVariants.some(({ node: variant }) =>
                              variant.title.includes(variantValue)
                            )
                        );
                        return {
                          ...option,
                          values: filteredOptionValues,
                        };
                      });

                      // const availableColors = correctedOptions.find(
                      //   (opt) => opt.name === "Farge"
                      // ).values;
                      // const unavailableColors = options
                      //   .find((opt) => opt.name === "Farge")
                      //   .values.filter(
                      //     (originalColor) =>
                      //       !availableColors.includes(originalColor)
                      //   );

                      // Remove images which have a filename of unavailable colors
                      // const filteredImages = images.filter((image) => {
                      //   const imageUrl = image?.node?.src;
                      //   return !unavailableColors.some((unavailableColor) => {
                      //     const regex = new RegExp(`/${unavailableColor}.`);
                      //     return regex.test(imageUrl);
                      //   });
                      // });

                      const updatedProduct = {
                        ...product,
                        node: {
                          ...product.node,
                          variants: {
                            ...product.node.variants,
                            edges: filteredVariants,
                          },
                          options: correctedOptions,
                          images: {
                            ...product.node.images,
                            edges: images,
                          },
                        },
                      };

                      // Remove all products which has no available variants
                      return filteredVariants.length > 0
                        ? updatedProduct
                        : undefined;
                    })
                    .filter(Boolean),
                },
              },
            },
          };
        })
        .catch((error) => {
          console.log("caught");
          throw new Error(error.message);
        });

      const modifiedResponse = new Response(JSON.stringify(json), {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
      });
      console.log("Intercepted Shopify collection successfully");
      return modifiedResponse;
    } catch (error) {
      console.error("ShopifyCollection interceptor error: ", error);
      return response;
    }
  },
});

/**
 * Default Shopify component settings: https://github.com/Shopify/buy-button-js/blob/master/src/defaults/components.js
 */

// English button version

export const collectionOptionBasicEn = {
  product: {
    styles: {
      product: {
        // DO NOT TOUCH
        "@media (min-width: 601px)": {
          "max-width": "calc(25% - 20px)",
          "margin-left": "20px",
          "margin-bottom": "50px",
          width: "calc(25% - 20px)",
        },
        "@media (max-width: 960px)": {
          "margin-bottom": "50px",
        },
      },
      // END OF DO NOT TOUCH
      button: {
        ":hover": {
          "background-color": "#68a5ba",
        },
        "background-color": "#73b7cf",
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
    buttonDestination: "modal",
    contents: {
      options: false,
    },
    text: {
      button: "View product",
    },
  },
  productSet: {
    text: {
      nextPageButton: "Se more products"
    },
    // contents: {
    //   pagination: false,
    // },
    styles: {
      products: {
        "@media (min-width: 601px)": {
          "margin-left": "-20px",
        },
      },
    },
  },
  modalProduct: {
    width: '1000px',
    contents: {
      img: false,
      imgWithCarousel: true,
      button: false,
      buttonWithQuantity: true,
    },
    styles: {
      product: {
        "@media (min-width: 601px)": {
          "max-width": "100%",
          "margin-left": "0px",
          "margin-bottom": "0px",
        },
      },
      button: {
        ":hover": {
          "background-color": "#68a5ba",
        },
        "background-color": "#73b7cf",
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
    text: {
      button: "Pre-order",
    },
  },
  option: {},
  cart: {
    styles: {
      button: {
        ":hover": {
          "background-color": "#68a5ba",
        },
        "background-color": "#73b7cf",
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
    text: {
      title: "Cart",
      total: "Subtotal",
      empty: "Your cart is empty.",
      notice: "Shipping and discount codes are added at checkout.",
      button: "Checkout",
    },
    popup: false,
  },
  toggle: {
    styles: {
      toggle: {
        "background-color": "#73b7cf",
        ":hover": {
          "background-color": "#68a5ba",
        },
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
  },
};

// Norwegian button version

export const collectionOptionBasicNo = {
  product: {
    styles: {
      product: {
        // DO NOT TOUCH
        "@media (min-width: 601px)": {
          "max-width": "calc(25% - 20px)",
          "margin-left": "20px",
          "margin-bottom": "50px",
          width: "calc(25% - 20px)",
        },
        "@media (max-width: 960px)": {
          "margin-bottom": "50px",
        },
      },
      // END OF DO NOT TOUCH
      button: {
        ":hover": {
          "background-color": "#68a5ba",
        },
        "background-color": "#73b7cf",
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
    buttonDestination: "modal",
    contents: {
      options: false,
    },
    text: {
      button: "Se produktet",
    },
  },
  productSet: {
    text: {
      nextPageButton: "Se flere produkter"
    },
    // contents: {
    //   pagination: false,
    // },
    styles: {
      products: {
        "@media (min-width: 601px)": {
          "margin-left": "-20px",
        },
      },
    },
  },
  modalProduct: {
    width: '1000px',
    contents: {
      img: false,
      imgWithCarousel: true,
      button: false,
      buttonWithQuantity: true,
    },
    styles: {
      product: {
        "@media (min-width: 601px)": {
          "max-width": "100%",
          "margin-left": "0px",
          "margin-bottom": "0px",
        },
      },
      button: {
        ":hover": {
          "background-color": "#68a5ba",
        },
        "background-color": "#73b7cf",
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
    text: {
      button: "Forhåndsbestill",
    },
  },
  option: {},
  cart: {
    styles: {
      button: {
        ":hover": {
          "background-color": "#68a5ba",
        },
        "background-color": "#73b7cf",
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
    text: {
      title: "Handlekurv",
      total: "Subtotal",
      empty: "Handlekurven din er tom.",
      notice: "Frakt- og rabattkoder legges til i kassen.",
      button: "Utsjekking",
    },
    popup: false,
  },
  toggle: {
    styles: {
      toggle: {
        "background-color": "#73b7cf",
        ":hover": {
          "background-color": "#68a5ba",
        },
        ":focus": {
          "background-color": "#68a5ba",
        },
      },
    },
  },
};

// Translations to fix after new button import

// button: "Se produktet",
// button: "Legg til handlekurv"

//First import Norwegian version, fix translations and then copy paste the same under the English version. Copy text part beforehand to be able to fix translations.

export default function ShopifyCollection({ name, id, options }) {
  const hasLoaded = useRef(false);
  // eslint-disable-next-line no-unused-vars
  const [_, setRerender] = useState(0)
  const rerender = () => setRerender(n => n + 1);

  const modalIframe = document.querySelector('iframe[name="frame-modal"]')
  const modalIframeDoc = modalIframe?.contentDocument || modalIframe?.contentWindow.document;
  useEffect(() => {
    if (modalIframeDoc) {
      const style = modalIframeDoc.createElement('style');
      style.textContent = `
        .shopify-buy__btn--close {
          background: white;
          outline: 0;
        }

        .shopify-buy__product__variant-img {
          border-radius: 15px;
        }

        .shopify-buy__btn--close:hover {
          transform: unset;
          transition: none;
        }
        .shopify-buy__btn--close span:first-of-type {
          color: black;
          position: relative;
          bottom: 4px;
        }
      `;
      modalIframeDoc.head.appendChild(style);
    }
  }, [modalIframeDoc])

  const injectStyles = useCallback((iframe) => {
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const style = iframeDoc.createElement('style');
      style.textContent = `
        .shopify-buy__product {
          margin-top: 0 !important;
          min-width: 300px;
        }

        .shopify-buy__product__variant-img {
          border-radius: 15px;
        }

        .shopify-buy__collection-products {
          display: flex;
          justify-content: flex-start;
          overflow: scroll;
          flex-wrap: nowrap;
        }

        /* Large screens */
        @media (min-width: 641px) {
          .shopify-buy__collection-products {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
      `;
      iframeDoc.head.appendChild(style);
    }
  }, []);

  const ShopifyBuyInit = useCallback(() => {
    var client = window?.ShopifyBuy.buildClient({
      domain: "maama-handmade.myshopify.com",
      storefrontAccessToken: "659bdde20607b6dd5f8d4dcf8abd0f21",
    });
    window?.ShopifyBuy.UI.onReady(client).then(function (ui) {
      return ui.createComponent("collection", {
        id,
        node: document.getElementById(name),
        moneyFormat: "%7B%7Bamount_with_comma_separator%7D%7D%20kr",
        options: {
          ...options,
          product: {
            ...options.product,
            events: {
              beforeInit: component => {
                Object.defineProperty(component, "isButton", {
                  get: function () {
                    return true;
                  }
                });
                Object.defineProperty(component, "options", {
                  get: function () {
                    return this.config[this.typeKey];
                  }
                });
                const actualOnButtonClick = component.onButtonClick;
                component.onButtonClick = function (event, target) {
                  event.stopImmediatePropagation();
                  this.options.buttonDestination = "modal"; // Make clicking the product image open the modal
                  actualOnButtonClick.call(this, event, target);
                  rerender(); // Ensure modal iframe style injection useEffect triggering
                };
              }
            }
          }
        },
      });
    }).then((component) => {
      const iframeInterval = setInterval(() => {
        const iframe = document.querySelector(`#${name} iframe`);
        if (iframe) {
          clearInterval(iframeInterval);
          injectStyles(iframe);
          // Attempt to click the pagination button so the user doesn't have to
          setTimeout(() => {
            iframe?.contentDocument?.querySelector?.('.shopify-buy__collection-pagination-button')?.click()
          }, 800)
        }
      }, 100); // Check every 100ms
    }).catch((error) => {
      console.error("Error creating Shopify component:", error);
    });
  }, [name, id, options, injectStyles]);

  useEffect(() => {
    if (window.ShopifyBuy && !hasLoaded.current) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
        hasLoaded.current = true;
      }
    }
  }, [ShopifyBuyInit]);

  return <div id={name}></div>;
}



