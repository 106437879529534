import MainPage from "./Pages/MainPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ReturnPolicy from "./Pages/ReturnPolicy";
import TermsOfService from "./Pages/TermsOfService";
import WhoAreWe from "./Pages/WhoAreWe";
import WhoAmI from "./Pages/WhoAmI";
import OrderProcess from "./Pages/OrderProcess";
import Eshop from "./Pages/Eshop";
import NextOpening from "./Pages/NextOpening";
import ContactPage from "./Pages/ContactPage";
import { useEffect } from "react";
import CollectionPatterns from "./Pages/Collections/CollectionPatterns";
import CollectionColours from "./Pages/Collections/CollectionColours";
import SizeChart from "./Pages/SizeChart";
import CollectionNightshopping from "./Pages/Collections/CollectionNightshopping"
import MaamaOutlet from "./Pages/Collections/MaamaOutlet.js";

export default function Router() {
  const pathName = window.location.pathname;

  // Append search params to all links
  useEffect(() => {
    if (pathName) {
      [...document.querySelectorAll("a")].forEach((e) => {
        const url = new URL(e.href);
        for (let [k, v] of new URLSearchParams(
          window.location.search
        ).entries()) {
          url.searchParams.set(k, v);
        }
        e.href = url.toString();
      });
    }
    console.log('Router', { pathName });
  }, [pathName]);

  if (pathName === "/return-policy") {
    return <main><ReturnPolicy /></main>;
  }
  if (pathName === "/terms-of-service") {
    return <main><TermsOfService /></main>;
  }
  if (pathName === "/privacy-policy") {
    return <main><PrivacyPolicy /></main>;
  }
  if (pathName === "/who-are-we") {
    return <main><WhoAreWe /></main>;
  }
  if (pathName === "/who-am-i") {
    return <main><WhoAmI /></main>;
  }
  if (pathName === "/order-process") {
    return <main><OrderProcess /></main>;
  }
  if (pathName === "/e-shop") {
    return <main><Eshop /></main>;
  }
  if (pathName === "/next-e-shop-opening") {
    return <main><NextOpening /></main>;
  }
  if (pathName === "/contact-us") {
    return <main><ContactPage /></main>;
  }
  if (pathName === "/collection-patterns") {
    return <main><CollectionPatterns /></main>;
  }
  if (pathName === "/collection-colours") {
    return <main><CollectionColours /></main>;
  }
  if (pathName === "/size-chart") {
    return <main> <SizeChart /></main>;
  }
  if (pathName === "/collection-nightshopping") {
    return <main> <CollectionNightshopping /></main>;
  }
  if (pathName === "/maama-outlet") {
    return <main> <MaamaOutlet /></main>;
  }
  if (pathName === "/") {
    return <main><MainPage /></main>;
  }
  return <main><MainPage /></main>;
}
