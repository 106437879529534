import React from 'react';
import { t } from "../../locale/locale";

import CollectionPumpkins from "../../img/Collections/Pumpkins/brown_pumpkin_square1.jpg"
import CollectionCheckers from "../../img/Collections/Checkers/IMG_3716.png"
import CollectionSeagulls from "../../img/AugustLaunch/9-2.jpg"
import CollectionHappyClouds from "../../img/AugustLaunch/IMG_1522.jpg"
import CollectionDarkChocolate from "../../img/Collections/Dark Chocolate/Photo 3612.png"
import CollectionMint from "../../img/Collections/Mint/IMG_3866.png"
import CollectionDeepForest from "../../img/AugustLaunch/IMG_1956.jpg"

const collectionLinks = [
    {
        image: CollectionPumpkins,
        collectionId: "568837505297",
        collectionPage: "/collection-patterns",
        alt: "Pumpkins",
        overlayText: t("collection-patterns-id-1-title-no-dash"), // Text to display on the overlay
    },
    {
        image: CollectionCheckers,
        collectionId: "576769229073",
        collectionPage: "/collection-patterns",
        alt: "Earty Checkers",
        overlayText: t("collection-patterns-id-2-title-no-dash"),
    },
    {
        image: CollectionSeagulls,
        collectionId: "561864442129",
        collectionPage: "/collection-patterns",
        alt: "Seagulls",
        overlayText: t("collection-patterns-id-3-title-no-dash"),
    },
    {
        image: CollectionHappyClouds,
        collectionId: "561864605969",
        collectionPage: "/collection-patterns",
        alt: "Happy Clouds",
        overlayText: t("collection-patterns-id-4-title-no-dash"),
    },
    // COLOURS
    {
        image: CollectionDarkChocolate,
        collectionId: "576665813265",
        collectionPage: "/collection-colours",
        alt: "Dark Chocolate",
        overlayText: t("collection-colours-id-1-title-no-dash"),
    },
    {
        image: CollectionMint,
        collectionId: "576673808657",
        collectionPage: "/collection-colours",
        alt: "Mint",
        overlayText: t("collection-colours-id-2-title-no-dash"),
    },
    {
        image: CollectionDeepForest,
        collectionId: "565100478737",
        collectionPage: "/collection-colours",
        alt: "Deep Forest",
        overlayText: t("collection-colours-id-3-title-no-dash"),
    },
];

export function ActiveCollectionsSection() {
    return (
        <section className="extra-section-we-are-open">
            {/* We are open */}
            <h1 className="header-main-page-extra-section">
                {t("home-section-extra-we-are-open-heading")}
            </h1>
            {/* Scroll through our newest collection */}
            <h2 className="subheader-main-page-extra-section">
                {t("home-section-extra-we-are-open-subheading")}
            </h2>
            <div className="grid-main-page-banners">
                {collectionLinks.map((collectionLink) => (
                    <div
                        key={collectionLink.collectionId}
                        style={{
                            position: "relative",
                            overflow: "hidden",
                            flex: "0 0 auto",
                            marginRight: "10px",
                        }}
                    >
                        <img
                            className="we-are-open-images"
                            src={collectionLink.image}
                            alt={collectionLink.alt}
                            onClick={() => {
                                const url = new URL(window.location.href);
                                url.pathname = collectionLink.collectionPage;
                                url.searchParams.set(
                                    "collectionId",
                                    collectionLink.collectionId
                                );
                                window.location.href = url;
                            }}
                        />
                        {/* Overlay with text */}
                        <div className="collection-label">
                            {collectionLink.overlayText}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}