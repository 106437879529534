import React from "react";
import YouTube from "react-youtube";

class IntroductionVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containerWidth: 800, // Initial container width
      containerElement: null, // Initialize containerElement to null
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    const containerElement = document.getElementById("video-container");
    if (containerElement) {
      const containerWidth = containerElement.offsetWidth;
      console.log("Container Width:", containerWidth);
      this.setState({ containerWidth, containerElement });
    } else {
      console.log("Container Element not found!");
    }
  }

  render() {
    const { containerWidth, containerElement } = this.state;
    const options = {
      height: "550",
      width: containerWidth.toString(), // Width is based on the container width
      playerVars: {
        autoplay: 1,
        controls: 1,
      },
    };

    // Conditional rendering of YouTube component
    return (
      <div id="video-container" ref={(ref) => (this.containerRef = ref)}>
        {containerElement && (
          <YouTube
            videoId="A88V1RD3upA"
            opts={options}
            onReady={this._onReady}
            id="video"
          />
        )}
      </div>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}

export default IntroductionVideo;
