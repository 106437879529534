import React from "react";
import "./Navbar.css";
import { t } from "../locale/locale";

import MaamaLogo from "../img/logos/maama_logo_colours.png";
import FreeDeliveryIcon from "../img/icons/free-delivery.png";
import { freeDeliveryOnOrdersAboveNOK, hasCollectionsLaunched, isNightshoppingTime } from "../helpers/helpers";

export default function Navbar() {
  function toggleNavbar() {
    const navMenu = document.getElementById("navbarNav");
    const isOpen = navMenu.classList.contains("show");
    if (isOpen) {
      navMenu.classList.remove("show");
    } else {
      navMenu.classList.add("show");
    }
  }

  function getNavLinkClassNames(pathname) {
    const isActive = pathname === window.location.pathname;
    if (isActive) {
      return "nav-link navbar-page-title active";
    } else {
      return "nav-link navbar-page-title";
    }
  }

  return (
    <div>
      <nav className="navbar navbar-expand-md bg-body-tertiary bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img className="maama-logo" src={MaamaLogo} alt="ma&ma logo" />
          </a>
          <button
            onClick={toggleNavbar}
            className="navbar-toggler"
            type="button"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className={getNavLinkClassNames("/")}
                  aria-current="page"
                  href="/"
                >
                  {t("navbar-section-home")}
                </a>
              </li>
              {isNightshoppingTime ? (
                <li className="nav-item">
                  <a className={getNavLinkClassNames("/collection-nightshopping")} href="/collection-nightshopping">
                    {t("navbar-section-nightshopping")}
                  </a>
                </li>
              ) : (
                <li className="nav-item">
                  <a className={getNavLinkClassNames("/e-shop")} href="/e-shop">
                    {t("navbar-section-eshop")}
                  </a>
                </li>
              )}
              <li className="nav-item">
                <a
                  className={getNavLinkClassNames("/who-am-i")}
                  href="/who-am-i"
                >
                  {t("navbar-section-who-am-i")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={getNavLinkClassNames("/order-process")}
                  href="/order-process"
                >
                  {t("navbar-section-order-process")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={getNavLinkClassNames("/size-chart")}
                  href="/size-chart"
                >
                  {t("navbar-section-size-chart")}
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className={getNavLinkClassNames("/maama-outlet")}
                  href="/maama-outlet"
                >
                  {t("navbar-section-maama-outlet")}
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className={getNavLinkClassNames("/contact-us")}
                  href="/contact-us"
                >
                  {t("navbar-section-contact-us")}
                </a>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </nav>
      {freeDeliveryOnOrdersAboveNOK > 0 && hasCollectionsLaunched && (
        <div className="under-navbar">
          <span><img src={FreeDeliveryIcon} alt="Free delivery" /></span>
          <p>{t('free-delivery-banner')} {freeDeliveryOnOrdersAboveNOK}kr</p>
        </div>
      )}

    </div>
  );
}
